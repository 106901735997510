/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.uppy-Container {
  height: 100%;
  max-width: 100%;
}

.uppy-Root {
  height: 100%;
  width: 100%;
}

.uppy-FileInput-btn {
  background-color: hsl(var(--theme-link));
  border-radius: 9999px;
  border: 1px solid hsl(var(--theme-link));
  color: white;
  font-family: var(--theme-sans);
  font-weight: 500;
  font-size: 14px;
  padding: 4px 12px;
  transition: all 0.2s ease-in-out;
}

.uppy-StatusBar-actionBtn--upload {
  background-color: hsl(var(--theme-primary)) !important;
  border: 2px solid hsl(var(--theme-primary)) !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
  font-size: 14px !important;
  border-radius: 30px !important;
  transition: all 0.2s ease-in-out !important;
}

.uppy-StatusBar-actionBtn--upload:hover {
  border: 2px solid white !important;
}

.uppy-DashboardContent-back {
  color: hsl(var(--theme-foreground)) !important;
}

.uppy-DashboardContent-addMore {
  color: hsl(var(--theme-foreground)) !important;
}

.uppy-FileInput-btn:hover {
  border: 1px solid white;
  opacity: 0.9;
  cursor: pointer;
}
.uppy-FileInput-btn:focus {
  border: 1px dashed white;
  outline: none;
}

.uppy-DragDrop-container {
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow:
    5px 5px 10px hsl(var(--theme-neu)),
    -5px -5px 10px hsl(var(--theme-neu2)) !important;
  border-radius: 20px !important;
  transition: all 0.2s ease-in-out;
}

.uppy-DragDrop--isDraggingOver {
  border: 1px dotted hsl(var(--theme-primary));
}

.uppy-DragDrop-container:hover {
  box-shadow:
    2px 2px 5px hsl(var(--theme-neu)),
    -2px -2px 5px hsl(var(--theme-neu2)) !important;
  cursor: pointer;
}

.uppy-DragDrop-container:focus {
  border: 1px dashed hsl(var(--theme-primary));
  outline: none;
}

.uppy-DragDrop-inner {
  display: none;
}

.uppy-Dashboard-browse {
  color: hsl(var(--theme-primary)) !important;
}

.uppy-Dashboard-browse:hover {
  text-decoration: underline;
  border-bottom: none !important;
  color: hsl(var(--theme-link)) !important;
  animation: 0.5s;
}

.uppy-Dashboard-browse:focus {
  text-decoration: underline;
  border-bottom: none !important;
  color: hsl(var(--theme-link)) !important;
  animation: 0.5s;
}

.uppy-c-btn {
  all: initial;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  font-family:
    -apple-system,
    system-ui,
    BlinkMacSystemFont,
    Segoe UI,
    Segoe UI Symbol,
    Segoe UI Emoji,
    Apple Color Emoji,
    Roboto,
    Helvetica,
    Arial,
    sans-serif;
  line-height: 1;
  align-items: center;
  color: inherit;
  display: inline-flex;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  transition-duration: 0.3s;
  transition-property: background-color, color;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  box-shadow: inset 0 0 24px hsl(var(--theme-primary)) !important;
  -webkit-box-shadow: inset 0 0 24px hsl(var(--theme-primary)) !important;
  transition: all 0.3s ease-in-out;
}

.react-pdf__Document {
  width: 100%;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.react-clock {
  display: block;
  position: relative;
}
.react-clock,
.react-clock *,
.react-clock *:before,
.react-clock *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-clock__face {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow:
    inset 10px 10px 20px hsl(var(--theme-neu)),
    inset -10px -10px 20px hsl(var(--theme-neu2));
  border-radius: 50%;
}
.react-clock__hand {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__hand__body {
  position: absolute;
  background-color: hsl(var(--theme-primary)) !important;
  transform: translateX(-50%);
}
.react-clock__mark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__mark__body {
  position: absolute;
  background-color: hsl(var(--theme-foreground)) !important;
  transform: translateX(-50%);
}
.react-clock__mark__number {
  position: absolute;
  left: -40px;
  width: 80px;
  text-align: center;
}
.react-clock__second-hand__body {
  background-color: red;
}

.rmsc multi-select {
  box-shadow:
    inset 5px 5px 10px hsl(var(--theme-neu)),
    inset -5px -5px 10px hsl(var(--theme-neu2));
}

.rmsc {
  --rmsc-main: hsl(var(--theme-primary)) !important;
  --rmsc-hover: hsl(var(--theme-neu2)) !important;
  --rmsc-selected: hsl(var(--theme-neu)) !important;
  --rmsc-border: hsl(var(--theme-foreground)) !important;
  --rmsc-gray: hsl(var(--theme-foreground)) !important;
  --rmsc-bg: var(--theme-background) !important;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 40px !important;
}
