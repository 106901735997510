@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: var(--theme-font-display);
}
